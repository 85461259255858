.languageSwitcher {
  &__wrapper {
    position: relative;
    padding: 8px;
    z-index: 1;

    &_active {
      border-radius: 23px 23px 0 0;
      background: #10234e;
    }
  }

  &__button {
    cursor: pointer;
  }

  &__dropdown {
    background: #10234e;
    position: absolute;
    top: 40px;
    right: 0;

    padding: 8px;
    border-radius: 0 0 23px 23px;

    ul li {
      padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__option {
      cursor: pointer;
    }
  }
}